import React from "react";
import Helmet from "react-helmet";
import Footer from "../components/Footer";
import ContactBar from "../components/ContactBar";
import Contact from "../components/Contact";
import Sidebar from "../components/Sidebar";
import Layout from "../components/Layout";

const Privacy = ({ location }) => (
  <Layout location={location}>
    <div id="privacy" className="page">
      <Helmet>
        <title>Datenschutzerklärung</title>
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <div className="content">
          <h1>Datenschutzerklärung</h1>

          <h2>Verantwortliche Person</h2>
          <p>
            <div>
              Die verantwortliche Person, welche Ihre Personendaten bearbeitet,
              ist
            </div>
            <div>TCM Praxis Paola Nakahara</div>
            <div>Baumackerstrasse 49, 8050 Zürich.</div>
          </p>

          <h2>Bearbeitungszweck</h2>
          <p>
            Die Erhebung und Bearbeitung Ihrer Personendaten dient in erster
            Linie der Kommunikation mit Ihnen. Dies beinhaltet auch
            Marketingzwecke und Beziehungspflege. Hierzu verwenden wir
            insbesondere Kommunikationsdaten und Stammdaten. Ebenfalls können
            wir Personendaten zur Erfüllung und Abwicklung des
            Behandlungsvertrages erheben und bearbeiten. Basierend auf Ihrer
            Nutzung unserer Website bearbeiten wir Ihre Daten zu den Zwecken,
            die wir im Folgenden erläutern.
          </p>

          <h3 className="subHeader">
            Bereitstellung der Website und Erstellung von Logfiles
          </h3>
          <p>
            Beim Besuch unserer Website erfassen wir automatisch allgemeine
            technische Besuchsinformationen in sogenannten Log-Dateien. Dazu
            gehört z.B. die IP-Adresse des verwendeten Geräts, von welchem aus
            der Besuch erfolgt. Die Erhebung und Verarbeitung dieser Log-Dateien
            erfolgt zum Zweck, die Nutzung unserer Website zu ermöglichen
            (Verbindungsaufbau), die Sicherheit und Stabilität unserer Systeme
            dauerhaft zu gewährleisten und zu erhöhen, die Optimierung unseres
            Internetangebots zu ermöglichen sowie zu internen statistischen
            Zwecken.
          </p>

          <h3 className="subHeader">
            Kontaktformular, Chat und E-Mail-Kontakt
          </h3>
          <p>
            Sie können sich mittels Kontaktformular, Chat oder E-Mail an uns
            wenden. Die dabei erfassten Daten bearbeiten wir, um Ihre Anfrage zu
            prüfen und mit Ihnen in Kontakt zu treten und z.B. einen Termin zu
            vereinbaren.
          </p>

          <h3 className="subHeader">Terminbuchung</h3>
          <p>
            Sie können online einen Termin bei uns buchen. Die dabei erfassten
            Daten bearbeiten wir, um den Termin zu erfassen, vorzubereiten und
            ein Patientenprofil anzulegen.
          </p>

          <h2>Weitergabe</h2>
          <p>
            Wir behandeln Ihre Personendaten vertraulich und geben diese nur an
            jene Dritte bekannt, welche in dieser Datenschutzerklärung
            aufgeführt sind.{" "}
          </p>
          <p>
            Für verschiedene administrative Tätigkeiten verwendet unsere Praxis
            externe Dienstleister. Diese externen Dienstleister können aufgrund
            ihrer Tätigkeit Einsicht in Ihre Personendaten erhalten, wobei sie
            vertraglich dazu verpflichtet sind, Ihre Personendaten stets
            vertraulich zu behandeln. In diesem Zusammenhang können Ihre
            Personendaten insbesondere an folgende Empfänger bekanntgegeben
            werden:
          </p>
          <ul>
            <li>
              Treuhänder im Zusammenhang mit der Führung der Buchhaltung und
              Erstellung und Versand der Rechnungen,
            </li>
            <li>
              Steuerberater, sofern dies für die Erstellung der Steuererklärung
              notwendig sein sollte,
            </li>
            <li>
              IT-Dienstleistungsunternehmen, welche die von uns verwendeten
              Softwareprogramme und Dienstleistungen bereitstellen sowie die
              Verwaltung der Website vornehmen,
            </li>
            <li>
              Inkassounternehmen, falls wir unsere Leistung auf dem
              Betreibungsweg geltend machen müssen,
            </li>
            <li>
              Anwaltskanzleien, falls eine Streitigkeit aus dem
              Behandlungsvertrag resultiert,
            </li>
            <li>Apotheken als Abgabestelle der Kräutermischungen,</li>
            <li>Kräuterfirmen zur Herstellung der individuellen Rezepturen.</li>
          </ul>
          <p>
            Darüber hinaus geben wir Ihre Personendaten an Krankenkassen und
            Versicherungen bekannt, insbesondere im Zusammenhang mit der
            Abrechnung der von uns erbrachten Leistungen. Auch können wir Ihre
            Personendaten an Ämter und Behörden, wie z.B. die kantonalen oder
            eidgenössischen Gesundheitsbehörden, bekannt geben, sofern dies im
            Zusammenhang mit der Führung unserer Praxis und den erteilten
            Bewilligungen notwendig werden sollte.{" "}
          </p>
          <p>
            Auch können wir Ihre Personendaten anderen Medizinal- und
            Gesundheitsfachpersonen (wie z.B. Ärzte, Naturheilpraktiker, etc.)
            bekanntgeben, sofern dies im Zusammenhang mit der medizinischen
            Behandlung notwendig erscheint, z.B. um Zweitmeinungen oder
            ergänzende Informationen einzuholen oder die Stellvertretung
            sicherzustellen. Sofern dies im Zusammenhang mit der medizinischen
            Behandlung notwendig erscheint, können wir auch andere Medizinal-
            und Gesundheitsfachpersonen (z.B. Ihren Hausarzt) um Auskunft über
            Ihre Krankengeschichte anfragen und geben in diesem Zusammenhang
            bekannt, dass Sie bei uns in Behandlung sind.
          </p>
          <p>
            Ihre Personendaten (wie z.B. Befunde, Therapievorschläge, etc.)
            dürfen auch mit Ihren Angehörigen geteilt werden.
          </p>

          <h2>Bekanntgabe ins Ausland</h2>
          <p>
            Wir geben Ihre Personendaten in folgende Länder bekannt: Schweiz.
          </p>

          <h2>Kommunikation</h2>
          <p>
            Bei der Kommunikation mittels unverschlüsselter E-Mail besteht das
            Risiko, dass Daten verloren gehen, abgefangen oder manipuliert
            werden. Die Vertraulichkeit von Daten kann bei der Übertragung
            mittels unverschlüsselter E-Mail nicht gewährleistet werden.
            Dasselbe gilt für Messenger-Dienste. Wir können die Vertraulichkeit
            der Kommunikation mittels unverschlüsselter E-Mail sowie
            Messenger-Diensten nicht garantieren. Sofern Sie uns mittels E-Mail,
            Messenger oder ähnlichen Diensten kontaktieren, verstehen wir dies
            als Einverständnis zur Nutzung dieser Kommunikationskanäle. Auch
            behalten wir uns vor, Sie durch diese Kommunikationskanäle zu
            kontaktieren.
          </p>

          <h2>Verwendung von Cookies</h2>
          <p>
            Cookies sind kleine Informationseinheiten, mit deren Hilfe Ihr
            Browser eindeutig identifiziert werden kann und in denen weitere
            Informationen wie Benutzereinstellungen abgelegt werden können. Wenn
            Sie unsere Website besuchen, werden Cookies automatisch auf Ihrem
            Endgerät gespeichert. Cookies helfen, Ihren Besuch auf der Website
            einfacher, angenehmer und sinnvoller zu gestalten. Wir verwenden
            folgende Cookies:
          </p>
          <ul>
            <li>
              Technisch notwendige Cookies: Diese Cookies sind für das
              Funktionieren unserer Website zwingend erforderlich und können in
              unseren Systemen nicht deaktiviert werden.
            </li>
            <li>
              Funktions-Cookies: Diese Cookies ermöglichen die Bereitstellung
              verbesserter Funktionen und die Personalisierung. Sie können von
              uns oder von Dritten, deren Dienste wir auf unsere Website nutzen,
              gesetzt werden.
            </li>
            <li>
              Analyse-Cookies: Diese Cookies ermöglichen es uns, Besuche zu
              zählen und Zugriffsquellen zu identifizieren, um die Leistung
              unserer Website zu bestimmen und zu verbessern.
            </li>
            <li>
              Marketing-Cookies: Diese Cookies und andere Tracking- Technologien
              ermöglichen es, ihnen personalisierte Werbung anzeigen zu lassen.
            </li>
          </ul>
          <p>
            Sie können Ihren Browser so einstellen, dass Cookies blockiert
            werden. Dies kann jedoch zur Folge haben, dass einige Bereiche der
            Website nicht funktionieren.
          </p>

          <h2>Google Kalender</h2>
          <p>
            Sie können auf der Webseite einen Termin buchen. Zu diesem Zweck
            betten wir einen Google-Kalender ein. Betreiber des entsprechenden
            Plugins ist Google LLC (1600 Amphitheatre Parkway, Mountain View, CA
            94043, USA; nachfolgend „Google“). Wenn Sie Seite für die
            Terminbuchung besuchen, wird eine Verbindung zu Servern von Google
            hergestellt. Dabei wird Google mitgeteilt, welche Seiten Sie
            besuchen.{" "}
          </p>
          <p>Alternativ können Sie Ihren Termin telefonisch buchen.</p>
          <p>
            <div>
              Die Datenschutzrichtlinie des Kalenderbetreibers Google finden Sie
              hier:{" "}
              <a
                href="https://www.google.com/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.google.com/policies/privacy/
              </a>
            </div>
            <div>
              Google verarbeitet Ihre Daten in den USA und hat sich dem EU-US
              Privacy Shield unterworfen:{" "}
              <a
                href="https://www.privacyshield.gov/EU-US-Framework"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.privacyshield.gov/EU-US-Framework
              </a>
            </div>
          </p>

          <h2>Kontakt</h2>
          <p>
            Bei Fragen zur Bearbeitung Ihrer Personendaten oder zur Ausübung
            Ihrer datenschutzrechtlichen Rechte können Sie uns unter
            tcm@paolanakahara.ch kontaktieren.
          </p>

          <h2>Aktualität und Änderungen</h2>
          <p>
            Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit
            ohne Vorankündigung zu aktualisieren und anzupassen. Es gilt die
            jeweils aktuelle, auf unserer Website publizierte Version.
          </p>
          <p>
            Mit der Nutzung unserer Website erklären Sie sich mit diesen
            Bedingungen und der entsprechenden Bearbeitung ihrer Personendaten
            einverstanden.
          </p>
          <p>Datenschutzerklärung-Version vom 22.08.2023.</p>
        </div>
      </div>
      <Footer />
    </div>
  </Layout>
);

export default Privacy;

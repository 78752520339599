import React from 'react';
import ContactForm from './ContactForm';

export default function Contact(props) {
  return (
    <div id="contact">
      <div className='container'>
        <h2>Kontakt</h2>
        <div className="address">
          <h3>Anschrift</h3>
          <div className="primary-address">
            <div>Praxis für Traditionelle Chinesische Medizin</div>
            <div>Paola Nakahara</div>
            <div>Baumackerstrasse 49</div>
            <div>8050 Zürich Oerlikon</div>
            <p></p>
            <p><strong>Termin nach Vereinbarung</strong></p>
            <div>Mobile: <a href="tel:+41762134087">+41 76 213 40 87</a></div>
          </div>
        </div>
        <div className="contact-form">
          <h3>Oder schreiben Sie mir per Email</h3>
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

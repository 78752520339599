import { Link } from 'gatsby';
import React from 'react';

export default function Footer(props) {
  const year = new Date().getFullYear();
  return (
    <footer id="footer">
      <div className='container'>
        <div className="social">
          <a href="https://www.facebook.com/pages/Paola-Hoffmann-Naturheilpraktikerin-TCM/669821569715250" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-facebook" />
          </a>
        </div>
        <div className="copy">
          <span>&copy; {year} Paola Nakahara</span>
          <span><Link to="/datenschutz/">Datenschutzerklärung</Link></span>
          <span>Umsetzung: <a href="https://codeplant.ch" target="_blank" rel="noopener noreferrer">codeplant GmbH</a></span>
          <span>Design: <a href="http://www.thomasledermann.ch" target="_blank" rel="noopener noreferrer">Thomas Ledermann</a></span>
        </div>
      </div>
    </footer>
  );
}

import React from 'react';
import { Link, graphql, StaticQuery } from "gatsby";
import CoachfrogLogo from '../assets/images/coachfrog-logo.svg';

const Sidebar = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const recentPosts = posts.slice(0, 4);
  return (
    <aside id="sidebar">
      <h3>Neuste Beiträge</h3>
      <ul className="recent-posts">
        {recentPosts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <li key={post.id}>
                <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
              </li>
            );
          })}
      </ul>
      <h3>Kontakt</h3>
      <div>Paola Nakahara</div>
      <div>Baumackerstrasse 49</div>
      <div>8050 Zürich Oerlikon</div>
      <div>Mobile: +41 76 213 40 87</div>
      <div>tcm@paolanakahara.ch</div>
      <a href="https://www.coachfrog.ch/de/fachperson/paola-hoffmann/?review" target="_blank" rel="noopener noreferrer" className="coachfrog">
        <p>Schreiben Sie einen Erfahrungsbericht</p>
        <img src={CoachfrogLogo} alt="Logo Coachfrog" />
      </a>
    </aside>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query BlogSidebarQueryKosten {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 5) {
          edges {
            node {
              id
              frontmatter {
                title
                path
              }
            }
          }
        }
      }
    `}
    render={data => <Sidebar data={data} {...props} />}
  />
);




import React, { Component } from 'react';

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = { success: false, validate: false, name: '', email: '', message: '' };
  }

  onChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  validate() {
    const { name, email, message } = this.state;
    return (name && email && message);
  }

  onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(this.validate()) {
      const data = new FormData();
      data.set('name', this.state.name);
      data.set('email', this.state.email);
      data.set('message', this.state.message);

      const that = this;

      var XHR = new XMLHttpRequest();
      XHR.open('POST', '/mailer_v2.php');

      XHR.addEventListener('load', function(event) {
        that.setState({ success: true, validate: false, name: '', email: '', message: '' })
      });
      XHR.send(data);
    }
    else {
      this.setState({ validate: true });
    }
  }

  renderErrorMessage() {
    if(this.state.validate && !this.validate()) {
      return (
        <div className="error">
          {(!this.state.name) ? <div><i className="fa fa-times"></i> Name wird benötigt</div> : null }
          {(!this.state.email) ? <div><i className="fa fa-times"></i> Email wird benötigt</div> : null }
          {(!this.state.message) ? <div><i className="fa fa-times"></i> Nachricht kann nicht leer sein</div> : null }
        </div>
      )
    }
  }
  renderSuccessMessage() {
    if(this.state.success) {
      return (
        <div className="success">
          <div><i className="fa fa-check"></i> Herzlichen Dank für Ihre Nachricht. Ich werde Sie baldmöglichst kontaktieren.</div>
        </div>
      );
    }
  }

  render() {
    return (
      <form method="post" encType="text/plain" onSubmit={this.onSubmit}>
        {this.renderErrorMessage()}
        {this.renderSuccessMessage()}
        <input type="text" placeholder="Username" id="username" name="username" autoComplete="off" onChange={this.onChange} />
        <input type="text" placeholder="Ihr Name" id="name" name="name" autoComplete="name" value={this.state.name} onChange={this.onChange} />
        <input type="text" placeholder="Ihre Email Adresse" name="email" autoComplete="email" value={this.state.email} onChange={this.onChange} />
        <textarea placeholder="Nachricht" name="message" id="message" cols="30" rows="10" value={this.state.message} onChange={this.onChange} ></textarea>
        <div className="actions">
          <button type="submit">Senden</button>
        </div>
      </form>
    );
  }

}

export default ContactForm;
